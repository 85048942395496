const getters = {
  token: state => state.user.token,
  merId: state => state.user.merId,
  merName: state => state.user.merName,
  enrollState: state => state.user.enrollState,
  enrollReason: state => state.user.enrollReason,
  landPageRoute: state => state.user.landPageRoute,
  
  status: state => state.user.status,
  userId: state => state.user.userId,
  storeId: state => state.user.storeId,
  phone: state => state.user.phone,
  name: state => state.user.name,
  loginName: state => state.user.loginName,
  realName: state => state.user.realName,
  userType: state => state.user.userType,
  avatar: state => state.user.avatar,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  errorLogs: state => state.errorLog.logs
}
export default getters
