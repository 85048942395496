
import request from "@/utils/request";

//公众号登录 获取token
export function mpLoginApi(data) {
  return request({
    url: '/wxapp/user/mpLogin',
    method: 'post',
    params: data
  })
}

//查询是否注册
export function QueryUserIsBindApi() {
  return request({
    url: '/wxapp/mer/auditStatus?channelCode=LKL',
    method: 'get'
  })
}

//获取微信公众号sdk配置
export function getWechatConfig(url) {
  return request({
    url: '/wxapp/mpBase/jsSdkConfig?url='+url,
    method: 'get'
  })
}

//获取商户信息
export function getBaseInfoApi(data) {
  return request({
    url: '/wxapp/mer/baseInfo',
    method: 'get',
    params: data
  })
}

//匿名查询商户资料页
export function getNMBaseInfoApi(data) {
  return request({
    url: '/wxapp/mer/anonymousBaseInfo',
    method: 'get',
    params: data
  })
}