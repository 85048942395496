import { mpLoginApi, QueryUserIsBindApi, getBaseInfoApi } from "@/api/login";

import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(), // 用户token
    enrollState: 100, //注册状态 100未查询  0: 未提交审核 1：资料已提交 2：已经人脸核身（审核中） 3：审核通过 4：审核失败
    enrollReason: '', //审核原因
    merId: '',  //商户id
    merName: '',  //商户名称
    shortName: '',  //商户简称
    nickName:'',    //用户昵称
    avatar: '', // 头像
    landPageRoute: '',  //登录前访问页面

    user: '',
    code: '',
    userId: '', // 用户id
    storeId: '', // 用户门店id
    name: '', // 真实姓名
    loginName: '', // 登录用户名
    realName: '', // 真实姓名
    phone: '', // 手机号
    status: '', 
    userType: '2', // 用户类型  1:店后台  2：总后台
    introduction: '', // 简介
    roles: [], // 角色  1超级管理员 1促销运营
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_MERID: (state, merId) => {
      state.merId = merId
    },
    SET_MERNAME: (state, merName) => {
      state.merName = merName
    },
    SET_SHORTNAME: (state, shortName) => {
      state.shortName = shortName
    },
    SET_ENROLLSTATE: (state, enrollState) => {
      state.enrollState = enrollState
    },
    SET_ENROLLREASON: (state, enrollReason) => {
      state.enrollReason = enrollReason
    },
    SET_NICKNAME: (state, nickName) => {
      state.avatar = nickName
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_LANDPAGEROUTE: (state, landPageRoute) => {
      state.landPageRoute = landPageRoute
    },

    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_STOREID: (state, storeId) => {
      state.storeId = storeId
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_REALNAME: (state, name) => {
      state.realName = name
    },
    SET_LOGINNAME: (state, name) => {
      state.loginName = name
    },
    SET_PHONE: (state, phone) => {
      state.phone = phone
    },
    SET_USERTYPE: (state, userType) => {
      state.userType = userType
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },

    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    }
  },

  actions: {    
    // 模拟数据
    debugData({ commit }) {
      return new Promise(resolve => {
        let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjdiZTFmODgxLTFjNDYtNDBiNi05ZmJmLTYxNzc3NTkzYjgxNSJ9.UGuSCHB1ABNCicjWyGtyIAUCOA-ZWNPYvpnWVzNcr463__6YMySvRXYLvhe6jaZ46tXyx5hS3J8SOarq6HdkgA';
        commit('SET_TOKEN', token)
        commit('SET_MERID', '100025');
        setToken(token)
        resolve()
      })
    },
    // 公众号登录
    LoginMp({ commit }, para) {
      return new Promise((resolve, reject) => {
        mpLoginApi(para).then(res => {
          if (res.code === 200) {
            commit('SET_TOKEN', res.data.token);
            // commit('SET_MERID', res.data.merId);
            setToken(res.data.token)
            // setToken(data.data.merId)
            resolve(res)
          } else {
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 查询是否注册绑定
    QueryUserIsBind({ commit }) {
      return new Promise((resolve, reject) => {
        QueryUserIsBindApi().then(res => {
          if (res.code === 200) {
            commit('SET_ENROLLSTATE',  res.data.auditStatus || 0);
            commit('SET_ENROLLREASON',  res.data.reason || '');
            commit('SET_MERID', res.data.merId||'');
            resolve(res)
          } else {
            resolve(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取商户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        // console.log("拉取user_info33333333333333");
        getBaseInfoApi().then(response => {
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            reject('error')
          }
          // todo
          // 现在全部admin最高权限，不做权限管理(src\permission.js;modules\permission.js相关代码已注释)
          const data = response.data
          commit('SET_MERID', data.data.merId);
          commit('SET_MERNAME', data.data.fullName);
          commit('SET_SHORTNAME', data.data.shortName);
          commit('SET_NICKNAME', data.data.nickName);
          commit('SET_AVATAR', data.data.headImg);

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_MERID', '');
        commit('SET_ENROLLSTATE',  100);
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    },

    //设置登录前访问页面
    setLandPage({ commit }, route) {
      commit('SET_LANDPAGEROUTE', route);
    }
  }
}

export default user
