/*
 * @version: 20220211
 * @Author: lcx
 * @Date: 2022-08-01 11:36:19
 * @LastEditors: zgy
 * @LastEditTime: 2022-08-03 17:35:52
 * @Description: description
 */
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/views/Index'),  //首页
      meta: { title: '天美云', requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
    },
    {
        path: '/apply',
        component: () => import('@/views/Apply'), 
        name: 'apply',
        meta: { title: '天美支付', requiresAuth: true }
    },
    {
      path: '/applylist',
      component: () => import('@/views/ApplyList'), 
      name: 'applylist',
      meta: { title: '开通产品', requiresAuth: true }
  },
    {
        path: '/memberinfo',
        component: () => import('@/views/MemberInfo'),
        name: 'memberinfo',
        meta: { title: '我的资料', requiresAuth: true }
    },
    {
        path: '/orderflow',
        component: () => import('@/views/OrderFlow'),
        name: 'orderflow',
        meta: { title: '订单流水', requiresAuth: true }
    },
    {
        path: '/ordersearch',
        component: () => import('@/views/OrderSearch'),
        name: 'ordersearch',
        meta: { title: '自定义查询', requiresAuth: true }
    },
    {
        path: '/qrcode',
        component: () => import('@/views/Qrcode'),
        name: 'qrcode',
        meta: { title: '门店收款码', requiresAuth: true }
    },
    {
        path: '/capitalreport',
        component: () => import('@/views/CapitalReport'),
        name: 'capitalreport',
        meta: { title: '经营报表', requiresAuth: true }
    },
    {
      path: '/store',
      name: 'store',
      component: () => import('@/views/Store'),
      meta: { title: '门店管理', requiresAuth: true }
    },
    {
      path: '/store-list',
      name: 'storelist',
      component: () => import('@/views/StoreList'),
      meta: { title: '门店列表', requiresAuth: true }
    },
    {
      path: '/store-edit/:id',
      name: 'storeedit',
      component: () => import('@/views/StoreEdit'),
      meta: { title: '门店管理', requiresAuth: true }
    },
    {
      path: '/store-add',
      name: 'storeadd',
      component: () => import('@/views/StoreEdit'),
      meta: { title: '门店管理', requiresAuth: true }
    },
    {
      path: '/staff',
      name: 'staff',
      component: () => import('@/views/Staff'),
      meta: { title: '店员管理', requiresAuth: true }
    },
    {
      path: '/staff-edit/:id',
      name: 'staffedit',
      component: () => import('@/views/StaffEdit'),
      meta: { title: '店员管理', requiresAuth: true }
    },
    {
      path: '/staff-add',
      name: 'staffadd',
      component: () => import('@/views/StaffEdit'),
      meta: { title: '店员管理', requiresAuth: true }
    },
    {
      path: '/term',
      name: 'term',
      component: () => import('@/views/Term'),
      meta: { title: '终端管理', requiresAuth: true }
    },
    {
      path: '/term-edit/:id',
      name: 'termedit',
      component: () => import('@/views/TermEdit'),
      meta: { title: '终端管理', requiresAuth: true }
    },
    {
      path: '/notice',
      name: 'notice',
      component: () => import('@/views/Notice'),
      meta: { title: '通知公告', requiresAuth: true }
    },
    {
      path: '/notice-detail/:id',
      name: 'noticedetail',
      component: () => import('@/views/NoticeDetail'),
      meta: { title: '通知公告', requiresAuth: true }
    },
    {
      path: '/business',
      name: 'business',
      component: () => import('@/views/Business'),
      meta: { title: '商户设置', requiresAuth: true }
    },
    {
        path: '/face',
        component: () => import('@/views/Face'),
        name: 'face',
        meta: { title: '人脸识别' }
    },
    {
        path: '/faceerr',
        component: () => import('@/views/FaceErr'),
        name: 'faceerr',
        meta: { title: '人脸识别' }
    },
    {
        path: '/unableplat',
        component: () => import('@/views/Unableplat'),
        name: 'unableplat',
        meta: { title: '天美云' }
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import('@/views/Agreement'),
      meta: { title: '用户协议' }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/Privacy'),
      meta: { title: '隐私政策' }
    },
    {
        path: '/404',
        component: () => import('@/views/Page404'),  //404页面
        meta: { title: '天美云' }
    },
    {
        path: '/:pathMatch(.*)',
        redirect:'/404'
    }
  ]
})

export default router