// import { createStore } from 'vuex'
// import state from './state'
// import actions from './actions'
// import mutations from './mutations'

// export default createStore({
//   state,
//   mutations,
//   actions,
//   modules: {}
// })

import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import user from './modules/user'
import getters from './getters'


const state = {

}

const mutations = {

}

const actions = {

}

const modules = {
    user
}
const store = createStore({
    state,
    mutations,
    actions,
    getters,
    modules,
    plugins: [createPersistedState({ storage: window.sessionStorage })]
})


export default store