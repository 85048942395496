/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
 import { Notify } from 'vant';
 import store from '@/store'
 import { getToken } from '@/utils/auth'
 import router from '@/router/index'
 import errorCode from '@/utils/errorCode'
 const DEFAULT_ERR_MSG = '呀，网络繁忙。没挤进去。请稍后重试吧~';
 
 const baseUrl = process.env.NODE_ENV == 'development' ? 'https://paymanage.teamax-rj.com/prod-api' : 'https://paymanage.teamax-rj.com/prod-api';
 
 // create an axios instance 
 const service = axios.create({
   baseURL: baseUrl, // api 的 base_url
   withCredentials: true,
   timeout: 30000 // request timeout
 })
 
 // request interceptor
 service.interceptors.request.use(
   config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false

    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
     return config
   },
   error => {
     // Do something with request error
     console.log(error) // for debug
     Promise.reject(error)
   }
 )
 
 // response interceptor
 service.interceptors.response.use( 
   res => {
     // 未设置状态码则默认成功状态
     const code = res.data.code || 200;
     // 获取错误信息
     const msg = errorCode[code] || res.data.msg || errorCode['default']
     // 二进制数据则直接返回
     if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
       return res.data
     }
     
     if(code === 401||code === 403){
      // Notify({ type: 'primary', message:  msg});
      //重新登录
      store.dispatch('FedLogOut').then(() => {
        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        // console.log(store.getters.landPageRoute)
        
        // console.log(router.currentRoute._value.meta);
        // if(router.currentRoute._value.meta.requiresAuth == true){
        //   router.push({
        //       path: '/login'
        //   });    
        // }
      }) 
     }else if(code != 200){
       Notify({ type: 'primary', message:  msg});
     }
     return Promise.resolve(res.data);
   },
   // 服务器状态码不是200的情况    
   error => {
     console.log("error",error); // for debug
     Notify({ 
       message:  error.message||DEFAULT_ERR_MSG
     });
     return Promise.reject(error)
   }
 )
 
 export default service