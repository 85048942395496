// import Cookies from 'js-cookie'

const MpTokenKey = 'mp-Token'

export function getToken() {
  // return Cookies.get(MpTokenKey)
  return window.localStorage.getItem(MpTokenKey)
}

export function setToken(token) {
  // return Cookies.set(MpTokenKey, token)
  return window.localStorage.setItem(MpTokenKey, token)
}

export function removeToken() {
  // return Cookies.remove(MpTokenKey)
  return window.localStorage.removeItem(MpTokenKey)
}
