import { createApp } from 'vue'
import { 
  ActionBar, ActionBarIcon, ActionBarButton, Divider, Popup, Overlay, Loading, Dialog, ContactCard, 
  Form, AddressEdit, AddressList, Field, CellGroup, Cell, SwipeCell, Icon, Stepper, Card, Checkbox, CheckboxGroup, 
  Button, Swipe, SwipeItem, PullRefresh, List, Tab, Tabs, SubmitBar, Toast, Skeleton, RadioGroup, Radio, Uploader, Picker,
  Image as VanImage, Area, Cascader, Empty, DatetimePicker, Switch, ImagePreview, Badge, Search 
} from 'vant'
import App from './App.vue'
import store from './store'
import router from './router'
import 'lib-flexible/flexible'
import 'vant/lib/index.css'; // 全局引入样式
// import jssdk from "weixin-js-sdk"


const app = createApp(App) // 创建实例

// app.config.globalProperties.$wx = jssdk
// // 全局过滤器
// app.config.globalProperties.$filters = {
//   prefix(url) {
//     if (url && url.startsWith('http')) {
//       return url
//     } else {
//       url = `http://backend-api-01.newbee.ltd${url}`
//       return url
//     }
//   }
// }

app.use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(RadioGroup)
  .use(Radio)
  .use(Uploader)
  .use(Picker)
  .use(VanImage)
  .use(Area)
  .use(Cascader)
  .use(Empty)
  .use(DatetimePicker)
  .use(Switch)
  .use(ImagePreview)
  .use(Badge)
  .use(Search)

app.use(router)
app.use(store)

app.mount('#app')